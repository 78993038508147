




























































import Component, { mixins } from 'vue-class-component'
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniSquareButton from "@/components/buttons/SygniSquareButton.vue";
import SygniModal from '@/components/layout/SygniModal.vue';
import SygniInput from "@/components/inputs/SygniInput.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniMultiSelect from "@/components/inputs/SygniMultiSelect.vue";
import SygniDatePicker from "@/components/inputs/SygniDatePicker.vue";
import SygniRangeInput from "@/components/inputs/SygniRangeInput.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import SygniButtonsGroup from "@/components/layout/SygniButtonsGroup.vue";
import { Filter, FilterFunctions, FilterHelpers, FilterMixin, FilterOperators } from "@/shared/mixins/FilterMixin";
import SygniCheckableButton from "@/components/buttons/SygniCheckableButton.vue";
import { Watch } from 'vue-property-decorator';
import { usedCurrencies } from '@/shared/consts';
import GenproxButton from '@/components/buttons/GenproxButton.vue';

@Component({
  components: {
    SygniModal,
    SygniCheckableButton,
    SygniRangeInput,
    SygniButtonsGroup,
    SygniDatePicker,
    SygniContainerTitle,
    SygniRoundedButton,
    SygniSquareButton,
    SygniSelect,
    SygniInput,
    SygniMultiSelect,
    GenproxButton
  },
})

export default class StatementItemsFilters extends mixins(FilterMixin) {
  itemsUrl: string = 'bank-statement/statement';
  toggleFilters: boolean = false;
  usedCurrencies: any = usedCurrencies;
  
  selectedStatementCurrencies: any[] = []
  selectedStatementDirections: any[] = []
  selectedStatusOptions: any[] = []
  bankAccountNumber: string = null

  statusOptions: any[] = [
    { label: 'Unmatched', value: 'unmatched' },
    { label: 'Matched', value: 'matched' },
    { label: 'Booked', value: 'booked' },
  ]

  receivables: any = { value: { from: null, to: null }}
  payables: any = { value: { from: null, to: null }}
  systemReceivables: any = { value: { from: null, to: null }}
  systemPayables: any = { value: { from: null, to: null }}

  filters: Record<string, Filter> = {
    fullNumber: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    issueDate: { value: null, getQueryValue: FilterFunctions.dateRange, operator: FilterOperators.like, type: 'dateRange' },
    entityCode: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    name: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    entityAccountNumber: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    amount: { value: { from: null, to: null }, getQueryValue: FilterFunctions.range, operator: FilterOperators.like, type: 'range' },
    currency: { value: [], getQueryValue: FilterFunctions.array, operator: FilterOperators.in, type: 'string' },
    direction: { value: [], getQueryValue: FilterFunctions.array, operator: FilterOperators.in, type: 'string' },
    amountSystem: { value: { from: null, to: null }, getQueryValue: FilterFunctions.range, operator: FilterOperators.like, type: 'range' },
    description: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    settled: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    oppositeAccount: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    status: { value: [], getQueryValue: FilterFunctions.array, operator: FilterOperators.in, type: 'string' },
  };

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData']
  }

  get toggleText(): string {
    return this.toggleFilters ? 'SHOW' : 'HIDE';
  }

  get tableQueryFilters() {
    return this.$store.getters['statements/getTransactionsTableQuery'].filters
  }

  handleFiltersToggle(): void {
    this.toggleFilters = !this.toggleFilters;
  }

  updateTypes(types: Array<string>) {
    this.filters.direction.value = types;
  }

  updateCurrencies(currencies: Array<string>) {
    if (currencies.includes('PLN')) {
      currencies.push(null)
    }
    this.selectedStatementCurrencies = currencies
    this.filters.currency.value = currencies;
  }

  toggleAllCurrencies() {
    if (this.filters.currency.value?.length < this.usedCurrencies.length) {
      this.updateCurrencies(this.usedCurrencies.map((el: any) => el.value))
    } else {
      this.updateCurrencies([])
    }
  }

  updateStatuses(statuses: Array<string>) {
    this.selectedStatusOptions = statuses
    this.filters.status.value = statuses;
  }

  toggleAllStatuses() {
    if (this.filters.status.value?.length < this.statusOptions.length) {
      this.updateStatuses(this.statusOptions.map((el: any) => el.value))
    } else {
      this.updateStatuses([])
    }
  }

  createSingleFilterQuery(filter: Filter, filterName: string): string {
    let query: string = this.createCustomQuery(filter, filterName);
    if (query !== '') return query;
    let queryValue = filter.getQueryValue(filter.value);
    if (queryValue === '') return '';
    switch (filter.type) {
      case('dateRange'):
        if (filter.value[0] === null || filter.value[1] === null) break;
        queryValue = filter.getQueryValue(filter.value, 0);
        query += `&filters[${filterName}][0][value]=${queryValue}`;
        query += `&filters[${filterName}][0][operator]=gte`;
        queryValue = filter.getQueryValue(filter.value, 1);
        query += `&filters[${filterName}][1][value]=${queryValue}`;
        query += `&filters[${filterName}][1][operator]=lte`;
        break;
      case('range'):
        if (filter.value.from === null && filter.value.to === null) break;
        queryValue = filter.getQueryValue(filter.value, 0);
        if (queryValue !== null) {
          query += `&filters[${filterName}][0][value]=${queryValue}`;
          query += `&filters[${filterName}][0][operator]=gte`;
        }
        queryValue = filter.getQueryValue(filter.value, 1);
        if (queryValue !== null) {
          query += `&filters[${filterName}][1][value]=${queryValue}`;
          query += `&filters[${filterName}][1][operator]=lte`;
        }
        break;
      case('string'): {
        query += `&filters[${filterName}][0][value]=${encodeURIComponent(queryValue)}`;
        query += `&filters[${filterName}][0][operator]=${filter.operator}`;
        break;
      }
    }
    return query;
  }

  applyFilters(): void {
    if (this.isLoading) return;
    this.$emit('filtersChange', this.getFilterQuery());
  }

  clearAll() {
    for (let key in this.filters) {
      if (Array.isArray(this.filters[key].value)) {
        this.filters[key].value = [];
      } else if (this.filters[key].value?.to) {
        this.filters[key].value.to = '';
        this.filters[key].value.from = '';
      } else {
        this.filters[key].value = '';
      }
    }
    this.updateCurrencies([])
    this.updateTypes([])
    this.updateStatuses([])
    this.bankAccountNumber = null
    this.applyFilters();
  }

  loadFiltersFromStore() {
    const filtersData = FilterHelpers.parseURLFilters(this.tableQueryFilters);

    if (filtersData) {
      let formattedFilters: any = {};

      for (let [key, value] of Object.entries(filtersData)) {
        key = key.substring(1);
        const index = key.indexOf(']');
        key = key.slice(0, index) + key.slice(index + 1);

        const keyName = key.slice(0, key.indexOf('['));

        const indexValue = key.substring(
            key.indexOf('[') + 1,
            key.lastIndexOf(']')
        );

        if (formattedFilters[keyName]) {
          if (indexValue == '0') {
            formattedFilters[keyName] = [formattedFilters[keyName]]
          } else {
            formattedFilters[keyName].push((value as any)[0]);
          }
        } else {
          formattedFilters[keyName] = value;
        }

      }

      for (let [key, value] of Object.entries(formattedFilters)) {
        if (this.filters[key].type == 'dateRange') {
          this.filters[key].value = [
            (value as any)[0].replace(' 00:00:00', ''),
            (value as any)[1].replace(' 23:59:59', ''),
          ];
        } else if (this.filters[key].type == 'range') {
          this.filters[key].value = {
            from: (value as any)[0],
            to: ((value as any)[1])
          }
        } else if (this.filters[key].type == 'string' && this.filters[key].operator != 'in') {
          this.filters[key].value = (value as any)[0]
        } else {
          this.filters[key].value = (value as any);
        }
      }
    }
  }

  @Watch('bankAccountNumber') onBankAccountNumberUpdate() {
    this.filters.entityAccountNumber.value = (this.bankAccountNumber) ? this.bankAccountNumber.replaceAll(' ', '') : null
  }

  beforeMount() {
    this.itemsUrl = `bank-statement/${this.$route.params?.id}/transaction`
  }

  mounted() {
    this.loadFiltersFromStore();
  }
}
