var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "statement-module"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('GenproxPageHeading', {
    attrs: {
      "title": _vm.pageTitle,
      "breadcrumbsTitle": _vm.pageTitle
    },
    scopedSlots: _vm._u([{
      key: "actionsLeft",
      fn: function fn() {
        return [_c('GenproxButton', {
          on: {
            "click": _vm.synchronizeTransactions
          }
        }, [_vm._v("Synchronise transactions")])];
      },
      proxy: true
    }, {
      key: "actionsRight",
      fn: function fn() {
        return [_c('GenproxButton', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: _vm.toggleColumnsText,
            expression: "toggleColumnsText",
            modifiers: {
              "hover": true
            }
          }],
          attrs: {
            "outline": true,
            "isSquare": true,
            "icon": _vm.hideAdditionalColumns ? _vm.EyeIcon : _vm.EyeSlashIcon
          },
          on: {
            "click": _vm.toggleAdditionalColumns
          }
        })];
      },
      proxy: true
    }])
  }), _c('statement-items-filters', {
    staticClass: "mb-4",
    on: {
      "filtersChange": _vm.applyFiltersEvent
    }
  }), _c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "statement-module__box"
  }, [_c('div', {
    staticClass: "statement-module__table"
  }, [_c('statement-items-table', {
    ref: "transactionsTable",
    attrs: {
      "statementId": _vm.statementId,
      "show-empty": true,
      "hideAdditionalColumns": _vm.hideAdditionalColumns,
      "show-page-number-options": true,
      "tableData": _vm.transactionsTable,
      "tablePerPageMutation": "statements/setTransactionsTablePerPage",
      "setTableBusyMutation": "statements/setTransactionsTableBusy",
      "busy": _vm.isBusy,
      "perPage": _vm.perPage,
      "getItemsAction": "statements/getTransactions"
    }
  })], 1)])])])]), _c('GenproxModal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.syncInfo,
      expression: "syncInfo"
    }],
    attrs: {
      "header": "Success",
      "size": "large",
      "description": "Transactions have been synchronized successfully. Here's a list of changes:",
      "confirmText": "OK",
      "cancelText": ""
    },
    on: {
      "close": _vm.closeSyncInfoModal,
      "confirm": _vm.closeSyncInfoModal
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('b-table', {
          ref: "uploadFilesTable",
          staticClass: "sygni-b-inner-table",
          attrs: {
            "innertable": "true",
            "fields": [{
              key: 'fullNumber',
              sortable: false,
              class: ['full-number']
            }, {
              key: 'status',
              sortable: false,
              class: ['status'],
              label: 'Action'
            }],
            "items": _vm.syncInfoItems
          },
          scopedSlots: _vm._u([{
            key: "cell(fullNumber)",
            fn: function fn(rowData) {
              var _rowData$item, _rowData$item2;

              return [_c('div', {
                staticClass: "full-report-link"
              }, [_vm._v(_vm._s(rowData !== null && rowData !== void 0 && (_rowData$item = rowData.item) !== null && _rowData$item !== void 0 && _rowData$item.fullNumber ? rowData === null || rowData === void 0 ? void 0 : (_rowData$item2 = rowData.item) === null || _rowData$item2 === void 0 ? void 0 : _rowData$item2.fullNumber : ''))])];
            }
          }, {
            key: "cell(status)",
            fn: function fn(rowData) {
              return [_c('div', {
                staticClass: "table__status d-flex justify-content-center align-items-center"
              }, [_c('sygni-rounded-button', {
                class: ['outline round', _vm.getTransactionStatusClass(rowData.item.status)],
                attrs: {
                  "hoverable": false
                }
              }, [_vm._v(_vm._s(_vm.getTransactionStatusText(rowData.item.status)))])], 1)];
            }
          }])
        })];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }