var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['marketplace-filters filters-container filters table', _vm.toggleFilters ? 'filters--hidden' : '']
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-2"
  }, [_c('sygni-input', {
    attrs: {
      "label": 'DOCUMENT NO.'
    },
    model: {
      value: _vm.filters.fullNumber.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.fullNumber, "value", $$v);
      },
      expression: "filters.fullNumber.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-date-picker', {
    staticClass: "border-collapse",
    attrs: {
      "label": 'TRANSFER DATE',
      "range": true
    },
    model: {
      value: _vm.filters.issueDate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.issueDate, "value", $$v);
      },
      expression: "filters.issueDate.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-input', {
    attrs: {
      "label": 'COUNTERPARTY CODE'
    },
    model: {
      value: _vm.filters.entityCode.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.entityCode, "value", $$v);
      },
      expression: "filters.entityCode.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-input', {
    attrs: {
      "label": 'COUNTERPARTY NAME'
    },
    model: {
      value: _vm.filters.name.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.name, "value", $$v);
      },
      expression: "filters.name.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-input', {
    attrs: {
      "label": 'COUNTERPARTY ACCOUNT NO.',
      "displayFormat": "bankAccount"
    },
    model: {
      value: _vm.bankAccountNumber,
      callback: function callback($$v) {
        _vm.bankAccountNumber = $$v;
      },
      expression: "bankAccountNumber"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-range-input', {
    attrs: {
      "displayFormat": 'currency',
      "label": 'AMOUNT'
    },
    model: {
      value: _vm.filters.amount.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.amount, "value", $$v);
      },
      expression: "filters.amount.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-multi-select', {
    attrs: {
      "clear-on-select": false,
      "close-on-select": false,
      "display-values": true,
      "label": 'TYPE',
      "multiple": true,
      "options": [{
        label: 'Receivables',
        value: 1
      }, {
        label: 'Payables',
        value: -1
      }],
      "preserve-search": false,
      "searchable": true
    },
    on: {
      "input": _vm.updateTypes
    },
    model: {
      value: _vm.selectedStatementDirections,
      callback: function callback($$v) {
        _vm.selectedStatementDirections = $$v;
      },
      expression: "selectedStatementDirections"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-multi-select', {
    attrs: {
      "clear-on-select": false,
      "close-on-select": false,
      "display-values": true,
      "label": 'CURRENCY',
      "multiple": true,
      "options": _vm.usedCurrencies,
      "preserve-search": false,
      "searchable": true,
      "select-all": true
    },
    on: {
      "input": _vm.updateCurrencies,
      "toggleAll": _vm.toggleAllCurrencies
    },
    model: {
      value: _vm.selectedStatementCurrencies,
      callback: function callback($$v) {
        _vm.selectedStatementCurrencies = $$v;
      },
      expression: "selectedStatementCurrencies"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-range-input', {
    attrs: {
      "displayFormat": 'currency',
      "label": 'AMOUNT PLN'
    },
    model: {
      value: _vm.filters.amountSystem.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.amountSystem, "value", $$v);
      },
      expression: "filters.amountSystem.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-input', {
    attrs: {
      "label": 'DESCRIPTION'
    },
    model: {
      value: _vm.filters.description.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.description, "value", $$v);
      },
      expression: "filters.description.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-input', {
    attrs: {
      "label": 'SETTLED'
    },
    model: {
      value: _vm.filters.settled.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.settled, "value", $$v);
      },
      expression: "filters.settled.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-input', {
    attrs: {
      "label": 'CONTRA ACCOUNT'
    },
    model: {
      value: _vm.filters.oppositeAccount.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.oppositeAccount, "value", $$v);
      },
      expression: "filters.oppositeAccount.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-multi-select', {
    attrs: {
      "clear-on-select": false,
      "close-on-select": false,
      "display-values": true,
      "label": 'STATUS',
      "multiple": true,
      "options": _vm.statusOptions,
      "preserve-search": false,
      "searchable": true,
      "select-all": true
    },
    on: {
      "input": _vm.updateStatuses,
      "toggleAll": _vm.toggleAllStatuses
    },
    model: {
      value: _vm.selectedStatusOptions,
      callback: function callback($$v) {
        _vm.selectedStatusOptions = $$v;
      },
      expression: "selectedStatusOptions"
    }
  })], 1), _c('div', {
    staticClass: "offset-6 col-2"
  }, [_c('div', {
    staticClass: "clear-all-button",
    on: {
      "click": function click($event) {
        return _vm.clearAll();
      }
    }
  }, [_vm._v("Clear all")])]), _c('div', {
    staticClass: "col-2"
  }, [_c('div', {
    staticClass: "show-results-button"
  }, [_c('GenproxButton', {
    staticClass: "w-full",
    attrs: {
      "isLoading": _vm.isLoading,
      "counter": _vm.itemsCount
    },
    on: {
      "click": function click($event) {
        return _vm.applyFilters();
      }
    }
  }, [_vm._v("Show results")])], 1)])]), _c('div', {
    class: ['filters__toggle', !_vm.toggleFilters ? 'filters__toggle--active' : ''],
    on: {
      "click": _vm.handleFiltersToggle
    }
  }, [_c('p', {
    staticClass: "filters__toggle-option"
  }, [_vm._v(_vm._s(_vm.toggleText))]), _c('span', {
    staticClass: "filters__toggle-arrow"
  }, [_vm._v(">>>")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }